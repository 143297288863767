export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        online_employees :'', 
        offline_employees :'', 
    },
    
    getters : {
        online_employees: (state )=> state.online_employees,
        offline_employees: (state )=> state.offline_employees,
    },


    mutations : { 
        setonline_employees :(state,online_employees ) => (state.online_employees = online_employees), 
        setoffline_employees :(state,offline_employees ) => (state.offline_employees = offline_employees), 
    },

    actions : {


        async get_online_employees( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_online_employees')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/attendance/online_employees?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setonline_employees', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async get_offline_employees( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_offline_employees')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/attendance/offline_employees?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setoffline_employees', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async create_attendance( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_attendance')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/attendance/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    comp_play_success_file('User Registred  Successfully');
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },

        async signout_employee_attendance( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'signout_employee_attendance' + id)
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/attendance/signout/" + id , data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    comp_play_success_file('User Registred  Successfully');
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },
 

    }


    
}