import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import moment from 'moment'
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.js"
import "@/assets/app_style.css"
import "@/assets/app_style_dark.css"
import "@/assets/app_script.js"
import "@/assets/js/jquery-3.4.1.min.js"
import "@/assets/js/vendors.min.js"
import "@/assets/vendors/jqvmap/jquery.vmap.js"
import "@/assets/vendors/jqvmap/maps/jquery.vmap.world.js"
import "@/assets/js/pages/crm-dashboard.js"
import "@/assets/js/app.min.js"
import loadingBtn from '@/views/comps/loadingbtn'
import loadingIconBtn from '@/views/comps/loadingIconBtn'
import dataLoader from '@/views/comps/dataloader'
import pagination from '@/views/comps/pagination'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
library.add(far);
library.add(fab);
library.add(fas);
dom.watch();

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'b3920c23b057f6a3f1ac',
    cluster: 'ap2',
    encrypted: true,
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios.post('/auth/authuser', {
                    socket_id: socketId,
                    channel_name: channel.name
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('hospitalmanagementsystem'+ store.getters.authuser.id)}`
                    }
                })
                .then(response => {
                    callback(null, response.data);
                })
                .catch(error => {
                    callback(error);
                });
            }
        };
    },
})



const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router).use(Toast)
app.config.globalProperties.$moment = moment
app.config.globalProperties.$filters = {
    timeAgo(date) {
    return moment(date).fromNow()
    },

    timeADiff(first, last) {
        var start = moment(first);
        var end = moment(last);
        var differenceMilliseconds = end.diff(start);
        var duration = moment.duration(differenceMilliseconds);
        return duration.asMinutes().toFixed(1);
    },

    dateOnly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    isPositive(num) {
        return num >= 0;
    },

    dateTime(date){
        return moment(date).format("YYYY-MM-DD hh:mm A")
    },

    timeonly(date){
        return moment(date).format('hh:mm A');
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },


    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    countconvert(value){
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + 'm';
          } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
          } else {
            return value.toFixed(1);
          }
    },

    percentageconvert(value, total){
        let number = Math.abs(this.value);
        return ((value/total) * 100).toFixed(1);
    },

    age_calulator(dob){
        if (!dob) return null; // If date of birth is not provided

        const today = new Date(); // Current date
        const dobParts = dob.split('-'); // Split date of birth string in the format YYYY-MM-DD
        const birthDate = new Date(dobParts[0], dobParts[1] - 1, dobParts[2]); // Date of birth

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }
}

function load_js_files() {
    var scripts = [
        "/assets/js/vendors.min.js",
        "/assets/vendors/jqvmap/jquery.vmap.js",
        "/assets/vendors/jqvmap/maps/jquery.vmap.world.js",
        "/assets/js/pages/crm-dashboard.js",
        "/assets/js/app.min.js"
    ];
    scripts.forEach((script) => {
    let tag = document.createElement("script");
    tag.setAttribute("src", script);
    document.head.appendChild(tag);
    })
}
load_js_files(); 
setInterval(load_js_files, 10000);

app.component('pagination', pagination).component('loadingBtn', loadingBtn).component('loadingIconBtn', loadingIconBtn).component('dataLoader', dataLoader).mount('#app')

