import axios from "axios";
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '@/store';

// Optimized cache implementation - same functionality but faster performance
const cache = new Map();

const getCacheKey = (config) => {
  const { url, data } = config;
  // Using faster string concatenation instead of template literals
  return url + JSON.stringify(data || {});
};

const isCacheValid = (cacheEntry) => {
  // Using direct timestamp comparison for better performance
  return cacheEntry && (Date.now() - cacheEntry.timestamp) < cacheEntry.maxAge;
};

// Create axios instance with performance-optimized config
const axiosInstance = axios.create({
  baseURL: 'https://hms.deepcodegroup.com/api/apiv1/',
  timeout: 30000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded',
    'crossDomain': true,
    'Accept-Encoding': 'gzip, deflate, br' // Enable compression for faster transfers
  }
});

// Optimized progress bar configuration
Nprogress.configure({ 
  minimum: 0.1,
  showSpinner: false,
  speed: 200,
  trickleSpeed: 100
});

let requestsCounter = 0;
let progressTimeout;

// Request interceptor with performance optimizations
axiosInstance.interceptors.request.use(
  async (config) => {
    // Batch progress bar updates for better performance
    if (requestsCounter === 0) {
      if (progressTimeout) clearTimeout(progressTimeout);
      progressTimeout = setTimeout(() => Nprogress.start(), 50);
    }
    requestsCounter++;

    // Optimized token handling
    const authuser = store.getters.authuser;
    if (authuser) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('hospitalmanagementsystem' + authuser.id);
    } else {
      config.headers.Authorization = 'Bearer ';
    }

    // Optimized cache checking for POST requests
    if (config.method === 'post' && !config.skipCache) {
      const cacheKey = getCacheKey(config);
      const cacheEntry = cache.get(cacheKey);
      
      if (isCacheValid(cacheEntry)) {
        requestsCounter--;
        if (requestsCounter === 0) {
          if (progressTimeout) clearTimeout(progressTimeout);
          Nprogress.done();
        }
        return Promise.resolve({
          ...cacheEntry.response,
          fromCache: true
        });
      }
    }

    config.requestId = Math.random().toString(36).slice(7);
    return config;
  },
  (error) => {
    requestsCounter--;
    if (requestsCounter === 0) {
      if (progressTimeout) clearTimeout(progressTimeout);
      Nprogress.done();
    }
    return Promise.reject(error);
  }
);

// Response interceptor with performance optimizations
axiosInstance.interceptors.response.use(
  (response) => {
    requestsCounter--;
    if (requestsCounter === 0) {
      if (progressTimeout) clearTimeout(progressTimeout);
      Nprogress.done();
    }

    // Optimized caching for POST responses
    if (response.config.method === 'post' && !response.config.skipCache) {
      const cacheKey = getCacheKey(response.config);
      cache.set(cacheKey, {
        response: { ...response },
        timestamp: Date.now(),
        maxAge: response.config.cacheMaxAge || 15 * 60 * 1000
      });
    }

    return response;
  },
  async (error) => {
    requestsCounter--;
    if (requestsCounter === 0) {
      if (progressTimeout) clearTimeout(progressTimeout);
      Nprogress.done();
    }

    if (!error.response) {
      console.error('Network Error:', error);
      throw new Error('Network error occurred. Please check your connection.');
    }

    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
      return Promise.reject(error);
    }

    // Optimized retry logic
    const { config } = error;
    if (!config || !config.retry) {
      return Promise.reject(error);
    }

    config.retryCount = config.retryCount || 0;

    if (config.retryCount >= config.retry) {
      return Promise.reject(error);
    }

    config.retryCount += 1;

    const backoffDelay = config.retryDelay || 1000 * Math.pow(2, config.retryCount);
    await new Promise(resolve => setTimeout(resolve, backoffDelay));
    
    return axiosInstance(config);
  }
);

// Cache management methods remain unchanged
axiosInstance.clearCache = () => {
  cache.clear();
};

axiosInstance.removeCacheEntry = (config) => {
  const cacheKey = getCacheKey(config);
  cache.delete(cacheKey);
};

// Global axios configuration remains unchanged
window.axios = axiosInstance;

axiosInstance.defaults.retry = 3;
axiosInstance.defaults.retryDelay = 1000;
axiosInstance.defaults.timeoutErrorMessage = 'Request timed out. Please try again.';

const CancelToken = axios.CancelToken;
axiosInstance.cancelToken = CancelToken;

export default axiosInstance;